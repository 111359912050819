import HelpPanel from "@amzn/awsui-components-react/polaris/help-panel"

const topics = {
  main: (
    <HelpPanel
      header={<h2>Partner</h2>}
      footer={<p>Filling out the form on this page will add a Partner.</p>}
    />
  ),
} as const

export default topics
