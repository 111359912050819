import { useCollection } from "@amzn/awsui-collection-hooks"
import {
  Box,
  ColumnLayout,
  Container,
  Link,
} from "@amzn/awsui-components-react"
import { Dimension } from "models/dimension"
import React from "react"

type Props = {
  data: Dimension
  loading: boolean
  error: any
}
const CounterLink: React.FC<{ href: string }> = ({ href, children }) => {
  return (
    <Link variant="awsui-value-large" href={href}>
      {children}
    </Link>
  )
}

const MaturityOverview: React.FC<Props> = ({ data, loading, error }) => {
  const { items, collectionProps } = useCollection(data.capabilities, {
    pagination: {
      pageSize: 10,
    },
    sorting: {},
    selection: {},
  })

  return (
    <Container>
      <ColumnLayout columns={3} variant="text-grid">
        <div>
          <Box variant="awsui-key-label">Maturity Score</Box>
          <CounterLink href={`/assessments/${data.id}/${data.name}/create`}>{data.score}</CounterLink>
        </div>
        <div>
          <Box variant="awsui-key-label">Maturity Recommendations</Box>
          <CounterLink href={`/partners/${data.id}/${data.name}/recommendations`}>
            {data.recommendations}
          </CounterLink>
        </div>
        <div>
          <Box variant="awsui-key-label">GTM</Box>
          <CounterLink href={`/partners/${data.id}/${data.name}/gtm`}>
            {data.gtms}
          </CounterLink>
        </div>
      </ColumnLayout>
    </Container>
  )
}
export default MaturityOverview
