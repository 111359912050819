import { TableProps } from "@amzn/awsui-components-react/polaris/table"
import { PartnerResponse } from "api/partners"
import NavigationLink from "pmsa-polaris/components/NavigationLink"
import { routeParams } from "routes"

import { VISIBLE_COLUMN_OPTIONS } from "./columnsNames"

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<PartnerResponse>[] =
  [
    {
      id: "id",
      cell: (item) => (
        <NavigationLink href={routeParams.partnerDetails({ id: item.spmsId })}>
          {item.id}
        </NavigationLink>
      ),
      header: "Partner ID",
      //minWidth: "100px",
      sortingField: "id",
    },
    {
      id: "partnerName",
      header: "Partner",
      cell: (item) => (
        // <Link
        //   //TODO: Figure out how to point this to awspartner portal properly
        //   href={`https://discover.awspartner.com/?kw=${item.partnerName}&type=allPartners`}
        //   target="_blank"
        //   external
        // >
        //   {item.partnerName}
        // </Link>
        <NavigationLink href={routeParams.partnerDetails({ id: item.spmsId })}>
          {item.partnerName}
        </NavigationLink>
      ),
      //minWidth: "100px",
      sortingField: "partnerName",
    },
    {
      id: "dimensions",
      header: "Dimensions",
      cell: (item) => item.dimensions,
      sortingField: "dimensions",
    },
    {
      id: "geos",
      header: "Geos",
      cell: (item) => item.geos,
      sortingField: "geos",
    },
    {
      id: "pdm",
      header: "PDM",
      cell: (item) => item.pdm,
      //minWidth: "100px",
      sortingField: "pdm",
    },
    {
      id: "pmsa",
      header: "PMSA",
      cell: (item) => item.pmsa,
      //minWidth: "100px",
      sortingField: "pmsa",
    },
    // {
    //   id: "fiscalYear",
    //   header: "Fiscal Year",
    //   cell: (item) => item.fiscalYear,
    //   //minWidth: "100px",
    //   sortingField: "fiscalYear",
    // },
    // {
    //   id: "createdDate",
    //   header: "Created Date",
    //   cell: (item) => item.createdDate.substring(0, 10),
    //   //minWidth: "100px",
    //   sortingField: "createdDate",
    // },
    // {
    //   id: "lastModifiedDate",
    //   header: "Last Modified Date",
    //   cell: (item) => item.lastModifiedDate.substring(0, 10),
    //   //minWidth: "100px",
    //   sortingField: "lastModifiedDate",
    // },
    // {
    //   id: "closeDate",
    //   header: "Close Date",
    //   cell: (item) => item.closeDate,
    //   width: "130px",
    //   //minWidth: "100px",
    //   sortingField: "closeDate",
    // },
  ]

export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: "Visible columns",
    options: VISIBLE_COLUMN_OPTIONS,
  },
]

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: "10 Partners" },
  { value: 30, label: "30 Partners" },
  { value: 50, label: "50 Partners" },
]
