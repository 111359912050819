import {
  Container,
  Header,
  Link,
  RadioGroup,
  SpaceBetween,
  Textarea,
  Wizard,
  WizardProps,
} from "@amzn/awsui-components-react"
import assessments, { AssessmentRequest } from "api/assessments"
import { AxiosError } from "axios"
import { Formik, FormikHelpers, FormikProps } from "formik"
import { initial } from "lodash"
import { Question } from "models/assessment"
import { DIMENSIONS } from "models/dimension"
import moment from "moment"
import { useAppContext } from "pmsa-polaris/context/AppContext"
import useFlashbar from "pmsa-polaris/hooks/useFlashbar"
import usePromise from "pmsa-polaris/hooks/usePromise"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { routeParams } from "routes"
import csvUtility from "service/csvUtility"

type AssessmentFormValues = {
  assessment: AssessmentRequest
}

const DEFAULT_FORM_VALUES = {
  assessment: {
    dimension: DIMENSIONS[0],
    questionaire: [] as Question[],
  },
}

const FormContent: React.FC = () => {

  const [searchParams] = useSearchParams()
  const importAssessment = Object.fromEntries(searchParams)

  const { id, dimension } = useParams<{
    id?: string
    dimension?: string
  }>()

  const [questions,setQuestions] = useState<Question[]>([])
  // const questions = useRef([])

  
  const [initialValues, setInitialValues] = useState<AssessmentFormValues>({
    assessment: {
      ...DEFAULT_FORM_VALUES.assessment,
      id: id!,
    },
  })
  const [activeStepIndex, setActiveStepIndex] = useState(0)

  const navigate = useNavigate()

  const [
    {
      loading: createAssessmentLoading,
      data: createdAssessment,
      error: createAssessmentError,
    },
    createAssement,
  ] = usePromise(assessments.create)

  const [
    {
      loading: updateAssessmentLoading,
      data: updatedAssessment,
      error: updateAssessmentError,
    },
    updateAssessment,
  ] = usePromise(assessments.update)

  const [
    {
      loading: getAssessmentLoading,
      data: existingAssessment,
      error: getAssessmentError,
    },
    getAssessment,
  ] = usePromise(assessments.get)

  const isLoading =
    createAssessmentLoading || updateAssessmentLoading || getAssessmentLoading

  const setFlashMessages = useFlashbar()
  


  useEffect(() => {
    if (id) {
      const fetchAssessment = async () => {
        const assessment = await getAssessment(id!, dimension!)
        const values: AssessmentFormValues = {
          assessment: {
            ...assessment,
          },
        }
        // If importing an assessment, set Answers and Notes
        if(id && importAssessment.assessment){
          try{
            const responses = JSON.parse(importAssessment.assessment)
            values.assessment.questionaire.forEach(el => {
              if(responses[el.capability]){
                el.answer = responses[el.capability]["answer"].toString()
                el.notes = responses[el.capability]["notes"]
              }
            })
          }
          catch{
            console.log("Import Parse Failed: " + importAssessment.assessment)
          }
        }
        // If not already answered then set default as 1
        if(assessment.questionaire.at(0)?.answer == null) {
          values.assessment.questionaire.forEach(el => {
            el.answer = 1
            el.notes = ""
          })
        }
        setInitialValues(values)
        setQuestions(assessment.questionaire);
      }
      fetchAssessment()
    }
  }, [])

  const handleSubmit = useCallback(
    async (
      { assessment: { ...assessment } }: AssessmentFormValues,
      { setSubmitting }: FormikHelpers<AssessmentFormValues>
    ) => {
      try {
        if (assessment) {
          const assessmentRequest: AssessmentRequest = {
            ...assessment,
          }
            const newAssessment = await createAssement({...assessmentRequest, id:id!})
            setFlashMessages([
              {
                content: "Partner assessed successfully",
                presist: true,
                type: "success",
              },
            ])
            navigate(routeParams.partnerDetails({ id: id! }))
          
        }
      } catch (error) {
        const err = error as AxiosError<{ statusCode: number; message: string }>
        setFlashMessages([
          {
            type: "error",
            content: "There was an issue with creating assessment.",
          },
        ])
      } finally {
        setSubmitting(false)
      }
    },
    [createAssement, navigate, id, setFlashMessages, updateAssessment]
  )
  // const validationSchema = useMemo(() => getValidationSchema(), [])
  const pageTitle = id ? "Update Assessment" : "Add Assessment"

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmit}
      validateOnChange={false}
    >
      {({
        handleSubmit,
        errors,
        values: { assessment },
        isSubmitting,
        setFieldValue,
      }: FormikProps<AssessmentFormValues>) => {
        return (
          <form
            onSubmit={handleSubmit}
            className={isLoading ? "loading" : undefined}
          >
            <Wizard
              i18nStrings={{
                stepNumberLabel: (stepNumber) => `Capability ${stepNumber}`,
                collapsedStepsLabel: (stepNumber, stepsCount) =>
                  `Capability ${stepNumber} of ${stepsCount}`,
                cancelButton: "Cancel",
                previousButton: "Previous",
                nextButton: "Next",
                submitButton: "Submit Assessment",
                optional: "optional",
              }}
              onSubmit={()=>handleSubmit()}
              onNavigate={({ detail }) =>
                setActiveStepIndex(detail.requestedStepIndex)
              }
              activeStepIndex={activeStepIndex}
              steps={questions.map((question,idx) => {
                return {
                  title: question.capability,
                  info: <Link variant="info">Info</Link>,
                  content: (
                    <Container
                      header={
                        <div>
                          <Header variant="h2">
                            {question.prompt}
                          </Header>
                          {question.lineOfQuestion}
                        </div>
                      }
                      footer={
                        <div>
                          <Header variant="h2">Notes</Header>
                          <Textarea
                            name={`assessment.questionaire[${idx}].notes`}
                            onChange={({ detail }) => setFieldValue(`assessment.questionaire[${idx}].notes`,detail.value)}
                            value={assessment.questionaire.at(idx)!.notes!}
                            placeholder="This is a placeholder"
                          />
                        </div>
                      }
                    >
                      <SpaceBetween direction="vertical" size="l">
                        <RadioGroup
                          name={`assessment.questionaire[${idx}].answer`}
                          onChange={({detail}) => setFieldValue(`assessment.questionaire[${idx}].answer`,detail.value)}
                          value={assessment.questionaire.at(idx)!.answer!.toString()}
                          items={[
                            {
                              value: "1",
                              label: (
                                <div>
                                  <strong>Initial</strong> - {question.options[0]}
                                </div>
                              ),
                            },
                            {
                              value: "2",
                              label: (
                                <div>
                                  <strong>Piloting</strong> - {question.options[1]}
                                </div>
                              ),
                            },
                            {
                              value: "3",
                              label: (
                                <div>
                                  <strong>Adopting</strong> - {question.options[2]}
                                </div>
                              ),
                            },
                            {
                              value: "4",
                              label: (
                                <div>
                                  <strong>Maturing</strong> - {question.options[3]}
                                </div>
                              ),
                            },
                            {
                              value: "5",
                              label: (
                                <div>
                                  <strong>Innovating</strong> - {question.options[4]}
                                </div>
                              ),
                            },
                          ]}
                        />
                      </SpaceBetween>
                    </Container>
                  )
                }
              })}
            />
          </form>
        )
      }}
    </Formik>
  )
}
export default FormContent
