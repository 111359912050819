const routes = {
  home: "/",
  partnerList: "/partners",
  partnerCreate: "/partners/create",
  partnerEdit: "/partners/:id/edit",
  partnerDetails: "/partners/:id",
  partnerRecommendations: "/partners/:id/:dimension/recommendations",
  // partnerRecommendationsCreate: "/partners/:id/recommendations/create",
  partnerGTMs: "/partners/:id/:dimension/gtm",
  // partnerGTMCreate: "/partners/:id/gtm/create",
  // assessmentList: "/assessments/",
  // assessmentDetails: "/assessments/:id",
  partnerAssessmentCreate: "/assessments/:id/:dimension/create",
  assessmentEdit: "/assessments/:id/:dimension/edit",
} as const

import { createRouteParams } from "pmsa-polaris/routeUtils"

export default routes
export const routeParams = createRouteParams(routes)
