import { Assessment } from "models/assessment"
import request from "pmsa-polaris/api/request"

export type AssessmentRequest = Omit<
  Assessment,
  "lastModifiedDate" | "createdDate" |  "assessmentId" | "partnerName" | "score" 
>


export type AssessmentResponse = Readonly<Assessment>

const assessmentsApi = {
  getAll: () => request.get<AssessmentResponse[]>("/assessments"),

  get: ( partnerId: string, dimension: string) =>
    request.get<AssessmentResponse>(`/assessments/${partnerId}/${dimension}`),

  create: (params: AssessmentRequest) =>
    request.post<AssessmentResponse>("/assessments", params),

  update: (partnerId: string, params: AssessmentRequest) =>
    request.put<AssessmentResponse>(`/assessments/${partnerId}/`, params),
}
export default assessmentsApi
