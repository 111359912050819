import {
  Box,
  Button,
  ButtonDropdown,
  Container,
  SpaceBetween,
  Spinner,
  Tabs,
} from "@amzn/awsui-components-react"
import Header from "@amzn/awsui-components-react/polaris/header"
import { Dimension, DIMENSIONS } from "models/dimension"
import React, { useState } from "react"
import { useParams } from "react-router-dom"

import { CapbilitiesTable } from "./CapabilitiesTable"
import MaturityOverview from "./MaturityOverview"
import AssessmentUploadModal from "../../components/AssessmentUploadModal"

type Props = {
  loading: boolean
  error: any
  data: Dimension[]
}

const DimensionsTabs: React.FC<Props> = ({ data: data, error, loading }) => {
  const { id: idParam } = useParams()
  const id = idParam!


  const [tabId, setTabId] = useState(data.length != 0? data[0].name :"Assessments")
  const tabs = data.map((el) => {
    return {
      label: el.name,
      id: el.name,
      content: (
        <Box>
          <SpaceBetween size="l">
            <MaturityOverview loading={loading} error={error} data={el} />
            <CapbilitiesTable loading={loading} error={error} data={el} />
          </SpaceBetween>
        </Box>
      ),
    }
  })

  const buttons = DIMENSIONS.map((el) => {
    return {
      text: el,
      href: `/assessments/${id}/${el}/create`,
      id: el,
    }
  })

  const emptyTabs = [{
    label: "Assessments",
    id: "Assessments",
    content: (
      <Box>
        <SpaceBetween direction="horizontal" size="m">
        No dimensions assessed yet
        </SpaceBetween>
      </Box>
    )
  }]

  return (

    (loading && (
      <div className="fullscreen-center">
        <Spinner size="large" />
      </div>
    )) || (
      <Container
        header={
          <Header
            variant="h2"
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <AssessmentUploadModal></AssessmentUploadModal>
                <ButtonDropdown items={buttons}>New Assessment</ButtonDropdown>
              </SpaceBetween>
            }
          >
            Dimensions
          </Header>
        }
        disableContentPaddings
      >
        <Tabs
          onChange={(event) => setTabId(event.detail.activeTabId)}
          activeTabId={tabId}
          variant="container"
          tabs={tabs.length==0?emptyTabs:tabs}
          ariaLabel="Resource details"
        />
      </Container>
    )
  )
}

export default DimensionsTabs
