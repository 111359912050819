import { SpaceBetween } from "@amzn/awsui-components-react"
import TextContent from "@amzn/awsui-components-react/polaris/text-content"
import partners from "api/partners"
import Breadcrumbs from "pmsa-polaris/components/Breadcrumbs"
import MainLayout from "pmsa-polaris/components/MainLayout"
import usePromise from "pmsa-polaris/hooks/usePromise"
import React, { useEffect } from "react"
import { useParams } from "react-router-dom"

import RecommendationsList from "./RecommendationsList"
import ToolsPanel from "./tool-topics"

const Recommendations = () => {
  const { id: idParam, dimension: dimensionParam } = useParams()

  const id = idParam!
  const dimension = dimensionParam!

  const [{ data: partner }, getPartner] = usePromise(partners.get)
  const [{ loading, error, data: recommendations }, getRecommendations] =
    usePromise(partners.getRecommendations)

  useEffect(() => {
    getRecommendations(id, dimension)
    getPartner(id)
  }, [getRecommendations, id, getPartner])

  return (
    <MainLayout
      breadcrumbs={
        <Breadcrumbs
          items={[
            { text: "Partners", href: "/" },
            {
              text: partner ? partner.partnerName : id,
              href: `/partners/${id}`,
            },
            { text: dimension, href: "" },
            { text: "Recommendations", href: "" },
          ]}
        />
      }
      tools={<ToolsPanel />}
    >
      <SpaceBetween size="l">
        <TextContent>
          <h1>Recommendations</h1>
        </TextContent>
        <RecommendationsList
          error={error}
          data={recommendations!}
          loading={loading}
        />
        {/* <DimensionsContainer id={id} partner={partner} /> */}
      </SpaceBetween>
    </MainLayout>
  )
}

export default Recommendations
