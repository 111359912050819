import Spinner from "@amzn/awsui-components-react/polaris/spinner"
import partners from "api/partners"
import { DIMENSIONS } from "models/dimension"
import MainLayout from "pmsa-polaris/components/MainLayout"
import usePromise from "pmsa-polaris/hooks/usePromise"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { textSpanContainsTextSpan } from "typescript"
import { isContext } from "vm"

import FormContent from "./FormContent"
// import toolsTopics from "./tools-topics"

const CreateUpdateAssessment = () => {
  const { id: idParam, dimension: dim } = useParams()
  const id = idParam!
  const dimension = dim!

// check if url dimension exists in dimension enum
  const invalidDimensionError = !!DIMENSIONS.toString()
    .split(",")
    .includes(dimension)
  const [{ data: partner }, getPartner] = usePromise(partners.get)
  useEffect(() => {
    getPartner(id)
  }, [getPartner, id])

  return (
    (id && invalidDimensionError && (
      <MainLayout
        breadcrumbItems={[
          { text: "Partners", href: "/" },
          {
            text: partner ? partner.partnerName : id,
            href: `/partners/${id}`,
          },
          { text: dimension, href: "" },
          { text: "Assessment", href: "" },
        ]}
        // contentType="wizard"
        maxContentWidth={1500}
        // toolsTopics={toolsTopics}
      >
        <FormContent />
      </MainLayout>
    )) || (
      <div className="fullscreen-center">
        <Spinner size="large" />
      </div>
    )
  )
}

export default CreateUpdateAssessment
