import { BasePartner } from "./shared"

export const DIMENSIONS = [
  "Container",
  "Serverless",
  "Database",
  "Analytics",
  "AIML",
] as const

export type Dimension = BasePartner & {
  name: typeof DIMENSIONS[number]
  capabilities: Capability[],
  score?: number,
  recommendations?: number,
  gtms?: number
}

export type Capability = {
  name: string
  subDimension: string
  maturityScore?: number
}

export const dimensionsMap :{[key: string]: typeof DIMENSIONS[number]} = {
  "Partner Maturity Assessment - Containers": DIMENSIONS[0],
  "Partner Maturity Assessment - Serverless": DIMENSIONS[1],
  "Partner Maturity Assessment - Database": DIMENSIONS[2],
  "Partner Maturity Assessment - Analytics": DIMENSIONS[3],
  "Partner Maturity Assessment - AIML": DIMENSIONS[4],
} 


