import "@amzn/awsui-global-styles/polaris.css"

import { SideNavigationProps } from "@amzn/awsui-components-react/polaris/side-navigation"
import Spinner from "@amzn/awsui-components-react/polaris/spinner"
import { CognitoUser } from "amazon-cognito-identity-js"
import Amplify, { Auth } from "aws-amplify"
import TopNavigationBar from "components/TopNavigationBar"
import CreateUpdateAssessment from "pages/CreateUpdateAssessment"
// import MyContextProvider from "context/WorkstreamCatalogContext"
import CreateUpdatePartner from "pages/CreateUpdatePartner"
import GTM from "pages/GTM"
// import CreateUpdateOpportunity from "pages/CreateUpdateOpportunity"
import Home from "pages/Home"
import DetailsSection from "pages/PartnerDetails"
import Recommendations from "pages/Recommendations"
import { onTokenExpired } from "pmsa-polaris/api/request"
import MainApp from "pmsa-polaris/components/MainApp"
import jwt from "pmsa-polaris/jwt"
import { createContext, useContext, useEffect, useState } from "react"
import { Route } from "react-router-dom"

import routes from "./routes"




onTokenExpired(async () => {
  const user = await Auth.currentAuthenticatedUser()
  jwt.token = user.getSignInUserSession()?.getIdToken().getJwtToken()!
})

const items: SideNavigationProps.Item[] = [
  {
    type: "section",
    text: "Partners",
    items: [
      {
        type: "link",
        text: "Partners",
        href: `#${routes.home}`,
      },
    ],
  },
]


const App = () => {
  const [error, setError] = useState(false)
  const [user, setUser] = useState<CognitoUser | null>(null)
  const [loading, setLoading] = useState(true)


  useEffect(() => {
    const configureAmplify = async () => {
      const config = await import("./config")
      Amplify.configure(config.default)
      Auth.configure(config.default)
    }

    ;(async () => {
      try {
        await configureAmplify()

        try {
          const user: CognitoUser = await Auth.currentAuthenticatedUser()
          jwt.token = user.getSignInUserSession()?.getIdToken().getJwtToken()!
          setUser(user)
        } catch (loginError) {
          await Auth.federatedSignIn({ provider: "AmazonFederate" } as any)
        }
      } catch (error) {
        console.error(error)
        setError(true)
      }
      setLoading(false)
    })()
  }, [])

  return (
    (loading && (
      <div className="fullscreen-center">
        <Spinner size="large" />
      </div>
    )) ||
    (user && (
      <MainApp
        // container={MyContextProvider}
        serviceNavigationProps={{ items }}
        topNavigation={<TopNavigationBar />}
      >
        <Route path={routes.home} element={<Home />} />
        <Route path={routes.partnerCreate} element={<CreateUpdatePartner />} />
        <Route path={routes.partnerEdit} element={<CreateUpdatePartner />} />
        
        <Route
          path={routes.partnerAssessmentCreate}
          element={<CreateUpdateAssessment />}
        />
        <Route
          path={routes.partnerRecommendations}
          element={<Recommendations />}
        />
        <Route path={routes.partnerGTMs} element={<GTM />} />
        <Route path={routes.partnerDetails} element={<DetailsSection/>} />
      </MainApp>
    ))
  )
}

export default App
