import { SpaceBetween, Spinner } from "@amzn/awsui-components-react"
import partners from "api/partners"
import { Partner } from "models/partner"
import usePromise from "pmsa-polaris/hooks/usePromise"
import { useEffect } from "react"

import DimensionsTabs from "./DimensionsTabs"

type Props = {
  partner?: Partner
  id: string
}

const DimensionsContainer: React.FC<Props> = ({ id, partner: partner }) => {
  const [{ loading, error, data: dimensions }, getDimensions] = usePromise(
    partners.getDimensions
  )

  useEffect(() => {
    getDimensions(id)
  }, [getDimensions, id])

  return (
    (loading && (
      <div className="fullscreen-center">
        <Spinner size="large" />
      </div>
    )) ||
    (dimensions && (
      <SpaceBetween size="l">
        <DimensionsTabs data={dimensions} loading={loading} error={error} />
      </SpaceBetween>
    )) || <div>{error}</div>
  )
}

export default DimensionsContainer
