 import * as React from "react";
 import Button from "@amzn/awsui-components-react/polaris/button";
 import FormField from "@amzn/awsui-components-react/polaris/form-field";
 import Badge from "@amzn/awsui-components-react/polaris/badge";
import csvUtility from "service/csvUtility";
import { AssessmentRequest } from "api/assessments";


 
 interface FileUploadProps {
     name: string;
     label?: string;
     description?: string;
     className?: string;
     onFileUpload?: (uploadedFile: File) => any;
 }
 interface FileUploadState {
     file?: File;
     errorMessage?: string;
 }
 
 class FileUpload extends React.PureComponent<FileUploadProps, FileUploadState> {
     hiddenFileInput = React.createRef<HTMLInputElement>();

     constructor(props: Readonly<FileUploadProps>) {
         super(props);
         this.state = {} as FileUploadState;
         this.handleFileUpload = this.handleFileUpload.bind(this);
         this.handleReset = this.handleReset.bind(this);
     }
 
     async handleFileUpload(uploadedFile: File) {
         console.log("handleFileUpload():Uploaded File name: " + uploadedFile.name);
         this.setState({ 
            file: uploadedFile,
            errorMessage: undefined
         } as FileUploadState);
         const assessment = await this.props.onFileUpload?.(uploadedFile) as AssessmentRequest
         const assessmentQueryString = '/?assessment='+ csvUtility.questionaireToAnswerString(assessment.questionaire)     
         window.location.href = `/assessments/${assessment.id}/${assessment.dimension}/create`+assessmentQueryString
     }
 
     handleReset = () => {
         console.log("handleReset() fired");
         this.setState({ file: undefined, errorMessage: undefined });
     };
 
     componentDidUpdate(): void {
         //setup state
     }
 
     fileUploadButton() {
         return (
             <FormField
                 id="fileUpload"
                 label={this.props.label ?? "Upload File"}
             >
                 <input
                     ref={this.hiddenFileInput}
                     id="chooseFileInput"
                     type="file"
                     hidden
                     multiple={false}
                     accept="xlsx"
                     // @ts-ignore
                     onChange={(event) => this.handleFileUpload(event.target.files[0])}
                 />
                 <Button
                     iconName="upload"
                     id="uploadFileButton"
                     formAction="none"
                     onClick={() => {
                         // @ts-ignore
                         this.hiddenFileInput.current.value = null; // allow for re-choosing the same file name
                         // @ts-ignore
                         this.hiddenFileInput.current.click();
                     }}
                 >
                     Upload File
                 </Button>
                 {
                     this.state.file?.name &&
                     <>
                         &nbsp;&nbsp;Uploaded File:&nbsp;&nbsp;
                         <Badge color="green">
                             {this.state.file?.name}
                         </Badge>
                     </>
                 }
             </FormField>
         );
     }
 
     render() {
         return (
                <>{this.fileUploadButton()}</>
             );
     }
 }
 
 export default FileUpload;
