import { Question, QuestionResponse } from "../models/assessment"
import assessmentsApi, {AssessmentRequest} from "../api/assessments"
import { utils, read, WorkSheet } from "xlsx";
import {dimensionsMap, DIMENSIONS } from "../models/dimension"


const csvUtility = {
  questionaireToAnswerString: (questions: Question[])=>{
    const responses: {
      [key: string]: QuestionResponse,
     } = {}
    for(let i = 0; i<questions.length; i++){
      const focus = questions[i]
      const response = {
        capability: focus["capability"], 
        answer: focus["answer"], 
        notes: focus["notes"]
      } as QuestionResponse
      responses[focus["capability"]] = response
    }
    
    return JSON.stringify(responses)
  },

  // applyImportedAnswers: (defaultAnswers: Question[],query: string) =>{
  //   // if(responses){
  //   //   for(let i = 0; i<defaultAnswers.length;i++){
  //   //     const question = defaultAnswers[i]
  //   //     if(responses[question.capability]){

  //   //     }
  //   //     defaultAnswers[i] = question
  //   //   }
  //   // }
  //   // return defaultAnswers
    
  // },

  uploadAssessment: async (uploaded: File) => {
    console.log("Parsing Excel File...")
    try{
      const assessment = await csvUtility.assessmentFromExcel(uploaded)
      // console.log("Verifying...")
      return assessment;
    } catch{
      return null;
    }
    
  },

  assessmentFromExcel: async (uploaded: File) => {
      const data = await uploaded.arrayBuffer()  
      const workbook = read(data)
      const dimensionTab = workbook.SheetNames[1]
      const assessment = workbook.Sheets[dimensionTab]
      const address = utils.encode_cell({c: 0, r: 1}) 
      const cell = assessment[address]
      const assessmentType = cell['v']
      return csvUtility.mapAssessment(assessment, dimensionsMap[assessmentType])
    },

  mapAssessment: (assessment: WorkSheet, dimension: string)=>{
    const mapping: AssessmentRequest = {
    id: window.location.pathname.split('/')[2],
    dimension: dimension as typeof DIMENSIONS[number],
    questionaire: csvUtility.mapQuestions(assessment, 4),
    }
    return mapping
  },

  mapQuestions: (assessment: WorkSheet, startRow: number) => {
    let endRow=4;
    while(typeof assessment[utils.encode_cell({c: 0, r: endRow+1})]=== 'object' && endRow < 1000){
      endRow++
    }
    const questions:Question[] = new Array(endRow - startRow+1)
    for(let i = 0; i<=endRow-startRow; i++){
      const addition: Question = {
        capability: assessment[utils.encode_cell({c: 0, r: startRow+i})]['v'],
        prompt: assessment[utils.encode_cell({c: 1, r: startRow+i})]['v'],
        lineOfQuestion: assessment[utils.encode_cell({c: 2, r: startRow+i})]['v'],
        options: [assessment[utils.encode_cell({c: 3, r: startRow+i})]['v'],
                  assessment[utils.encode_cell({c: 4, r: startRow+i})]['v'],
                  assessment[utils.encode_cell({c: 5, r: startRow+i})]['v'],
                  assessment[utils.encode_cell({c: 6, r: startRow+i})]['v'],
                  assessment[utils.encode_cell({c: 7, r: startRow+i})]['v']],
        answer: assessment[utils.encode_cell({c: 8, r: startRow+i})]['v'],
        notes: assessment[utils.encode_cell({c: 9, r: startRow+i})]['v'],
        weightage: 1
      }
      questions[i] = addition
    }
    return questions
  },

  
}
export default csvUtility
