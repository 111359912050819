import * as React from "react";
import {
    Box,
    Button,
    Modal,
    SpaceBetween,
    Form
  } from "@amzn/awsui-components-react"
  import FileUpload from "./FileUpload"
  import csvUtility  from "../service/csvUtility"

  const AssessmentUploadModal: React.FC = () => {
    const [visible, setVisible] = React.useState(false);

    return (
        <Button onClick={()=>setVisible(!visible)}>Upload Assessment
        <Modal
        onDismiss={() => setVisible(true)}
        visible={visible}
        closeAriaLabel="Close modal"
        footer={
            <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link">Cancel</Button>
            </SpaceBetween>
            </Box>
        }
        header="Upload an Assessment"
        >
        <Form>
          <FileUpload name="csvUploader" 
            onFileUpload = {csvUtility.uploadAssessment}/>
        </Form>
        </Modal>
        </Button>
    );
}
export default AssessmentUploadModal