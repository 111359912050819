export const DEFAULT_COLUMN_IDS = [
  "partnerName",
  "dimensions",
  "geos",
  "pdm",
  "pmsa",
]

export const VISIBLE_COLUMN_OPTIONS = [
  // { id: "fiscalYear", label: "Fiscal Year" },
  // { id: "closeDate", label: "Close Date" },
  // { id: "lastModifiedDate", label: "Last Modified Date" },
  // { id: "createdDate", label: "Created Date" },
]

export const SEARCHABLE_COLUMNS = ["partnerName", "dimensions", "pdm", "pmsa","geos"]
